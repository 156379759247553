@import 'https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,400;0,700;1,400;1,700&display=swap';
:root {
  --toast-info: #385bbb;
  --toast-info-rgb: 56, 91, 187;
  --toast-info-lighter: hsl(224, 54%, 90%);
  --toast-info-darker: hsl(224, 54%, 35%);
  --toast-warning: #f3950d;
  --toast-warning-rgb: 243, 149, 13;
  --toast-warning-lighter: hsl(35, 91%, 90%);
  --toast-warning-darker: hsl(35, 91%, 40%);
  --toast-error: #d32f2f;
  --toast-error-rgb: 211, 47, 47;
  --toast-error-lighter: hsl(0, 68%, 90%);
  --toast-error-darker: hsl(0, 68%, 43%);
  --toast-success: #388e3c;
  --toast-success-rgb: 56, 142, 60;
  --toast-success-lighter: hsl(120, 54%, 90%);
  --toast-success-darker: hsl(120, 54%, 35%);
}
@-webkit-keyframes toastSlideFromTop {
  0% {
    opacity: 0;
    translate: 0 calc(-100% - 24px);
  }
  100% {
    opacity: 1;
    translate: 0 0;
  }
}
@keyframes toastSlideFromTop {
  0% {
    opacity: 0;
    translate: 0 calc(-100% - 24px);
  }
  100% {
    opacity: 1;
    translate: 0 0;
  }
}
@-webkit-keyframes toastSlideFromBottom {
  0% {
    opacity: 0;
    translate: 0 calc(100% + 24px);
  }
  100% {
    opacity: 1;
    translate: 0 0;
  }
}
@keyframes toastSlideFromBottom {
  0% {
    opacity: 0;
    translate: 0 calc(100% + 24px);
  }
  100% {
    opacity: 1;
    translate: 0 0;
  }
}
@-webkit-keyframes toastFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes toastFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes progressBar {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
}
@keyframes progressBar {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
}
.toast {
  position: fixed;
  font-family: 'Titillium Web', system-ui, helvetica, sans-serif;
  font-size: 14px;
  line-height: 1;
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  padding: 12px 16px;
  width: 100%;
  max-width: -webkit-max-content;
  max-width: -moz-max-content;
  max-width: max-content;
  -webkit-animation: toastSlideFromTop 0.6s ease-out forwards;
  animation: toastSlideFromTop 0.6s ease-out forwards;
}
.toast .toast-progress {
  display: none;
}
.toast.toast-auto-close {
  padding: 12px 16px 15px;
}
.toast.toast-auto-close .toast-progress {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  border-radius: 50px;
  transform-origin: left;
  z-index: -1;
}
.toast.toast-error {
  background-color: var(--toast-error-lighter);
  color: var(--toast-error);
  border-color: var(--toast-error);
}
.toast.toast-error.toast-auto-close .toast-progress {
  background-color: rgba(var(--toast-error-rgb), 0.45);
}
.toast.toast-error .toast-close button:hover {
  background-color: rgba(var(--toast-error-rgb), 0.15);
}
.toast.toast-success {
  background-color: var(--toast-success-lighter);
  color: var(--toast-success);
  border-color: var(--toast-success);
}
.toast.toast-success.toast-auto-close .toast-progress {
  background-color: rgba(var(--toast-success-rgb), 0.45);
}
.toast.toast-success .toast-close button:hover {
  background-color: rgba(var(--toast-success-rgb), 0.15);
}
.toast.toast-info {
  background-color: var(--toast-info-lighter);
  color: var(--toast-info);
  border-color: var(--toast-info);
}
.toast.toast-info.toast-auto-close .toast-progress {
  background-color: rgba(var(--toast-info-rgb), 0.45);
}
.toast.toast-info .toast-close button:hover {
  background-color: rgba(var(--toast-info-rgb), 0.15);
}
.toast.toast-warning {
  background-color: var(--toast-warning-lighter);
  color: var(--toast-warning);
  border-color: var(--toast-warning);
}
.toast.toast-warning.toast-auto-close .toast-progress {
  background-color: rgba(var(--toast-warning-rgb), 0.45);
}
.toast.toast-warning .toast-close button:hover {
  background-color: rgba(var(--toast-warning-rgb), 0.15);
}
.toast[data-position='top-left'] {
  top: 24px;
  left: 24px;
  transform: translateX(0);
}
.toast[data-position='top-center'] {
  top: 24px;
  bottom: initial;
  left: 50%;
  right: initial;
  transform: translateX(-50%);
}
.toast[data-position='top-right'] {
  top: 24px;
  bottom: initial;
  left: initial;
  right: 24px;
  transform: translateX(0);
}
.toast[data-position='bottom-left'] {
  top: initial;
  bottom: 24px;
  left: 24px;
  right: initial;
  transform: translateX(0);
}
.toast[data-position='bottom-center'] {
  top: initial;
  bottom: 24px;
  left: 50%;
  right: initial;
  transform: translateX(-50%);
}
.toast[data-position='bottom-right'] {
  top: initial;
  bottom: 24px;
  left: initial;
  right: 24px;
  transform: translateX(0);
}
.toast[data-position^='top'] {
  -webkit-animation: toastSlideFromTop 0.6s ease-out forwards;
  animation: toastSlideFromTop 0.6s ease-out forwards;
}
.toast[data-position^='bottom'] {
  -webkit-animation: toastSlideFromBottom 0.6s ease-out forwards;
  animation: toastSlideFromBottom 0.6s ease-out forwards;
}
.toast-wrapper {
  display: flex;
}
.toast .toast-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  margin-right: 10px;
}
.toast .toast-content {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.toast .toast-content .toast-title {
  font-weight: 700;
  font-size: 18px;
}
.toast .toast-content .toast-message {
  font-weight: normal;
  line-height: 1.1;
  font-size: 15px;
}
.toast .toast-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.toast .toast-close button {
  outline: none !important;
  padding: 0 !important;
  margin: 0 !important;
  border: none !important;
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
}
.toast-list {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
} /*# sourceMappingURL=toastinette.min.css.map */
