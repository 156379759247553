/* ─────────────────────────────────────────────────────────
/* ------------------ C S S   S T A R T --------------------
/* ───────────────────────────────────────────────────────── */

/* ─── BASE ──────────────────────────────────────────────── */
*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
html {
  /*            overflow: auto;*/
  height: 550px !important;
  width: 270px;
}
body {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  /*            overflow: auto;*/
  height: 550px !important;
  width: 270px !important;
}

main {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.admintab .icon-dis svg {
  color: white;
  font-size: 30px;
}
.font-weight-bold {
  font-weight: bold;
}
.IoArrowForward {
  font-size: 27px;
}

/* ─── RESPONSIVE ────────────────────────────────────────── */
@media screen and (max-width: 1200px) {
}
@media screen and (max-width: 1024px) {
}
@media screen and (max-width: 768px) {
}
@media screen and (max-width: 480px) {
}

.slick-prev,
.slick-next {
  display: none !important;
}
.leaves-list .tab-content {
  height: 600px;
  overflow: auto;
}

/* ─────────────────────────────────────────────────────────
/* ------------------- C S S   E N D -----------------------
/* ───────────────────────────────────────────────────────── */
