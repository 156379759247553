/*======= attendance page =======*/

* {
  font-family: 'poppins';
}

body {
  box-sizing: border-box;
  background: linear-gradient(133.27deg, #f2fafc 0%, #d9e7f5 60.75%);
  background-attachment: fixed;
  zoom: 60%;
  position: relative;
  height: 100%;
}

.version_code {
  float: right;
  margin-right: 10px;
}

.footer {
  position: absolute;
  padding: 13px;
  bottom: 0px;
  width: 100%;
}

/* .float-btn-bottom,
.application-link {
  width: fit-content;
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 9999;
} */

/* .punchin-time,
.punchout-time {
  position: absolute;
} */

#root
  > section
  > div
  > div
  > div.col-12.mt-4.text-center.extra-added-btn.d-flex.flex-wrap
  > div.col-12.text-center.mt-4
  > div
  > input[type='tel']:nth-child(1) {
  margin-left: 0px !important;
}

#root
  > section
  > div
  > div
  > div.col-12.mt-4.text-center.extra-added-btn.d-flex.flex-wrap
  > div.col-12.text-center.mt-4
  > div
  > input[type='tel']:nth-child(6) {
  margin-right: 0px !important;
}

.outtime {
  text-align: end !important;
}

.outtime2 {
  text-align: end !important;
}

.outtime3 {
  text-align: end !important;
  /* margin-left: 93px !important; */
}

/* .punchin-date,
.punchout-date {
  margin-top: 49px;
} */

.am1 {
  float: right;
  position: relative;
  left: 14vh;
}

.am2 {
  float: right;
  position: relative;
  left: 14vh;
}

/* .clock-time {
  position: absolute;
  margin-right: auto;
} */

.hrms_content {
  height: 750px;
  overflow: auto;
  margin-top: 30px;
}

.nav-item {
  width: 50%;
}
.nav-tabs {
  padding: 0px 15px 0px 15px !important;
}

.row {
  margin-left: 0px;
  margin-right: 0px;
}

.log-out {
  text-align: right;
}

.container {
  max-width: 1250px;
}

#punch-in-out {
  justify-content: space-between;
}

#punch-in-out .col-lg-5 {
  padding: 10px 20px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: auto;
  flex: 1 0 auto;
}

#punch-in-out .col-lg-5:first-child {
  margin-right: 20px;
}

#punch-in-out .col-lg-5 p {
  font-size: 16px;
}

#punch-in-out .col-lg-5 button {
  padding: 5px 19px;
  font-weight: 600;
  color: white;
  border: none;
  border-radius: 5px;
  width: 100%;
}

.checkin-btn {
  background: #19da4f;
}

.punchout-btn {
  padding: 5px 12px !important;
  background: #5450d6;
}

.punch-in span {
  float: right;
}

.history-row {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  position: relative;
}

.profile-row {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  position: relative;
  padding: 15px;
}

.icon-dis {
  border-radius: 50%;
  background: #4934b6;
  text-align: center;
  width: 50px;
  height: 50px;
  padding: 10px;
  margin: 0px auto;
}

.icon-dis svg {
  height: 30px;
  width: 30px;
}

.dis-1 {
  background: #4934b6 !important;
}

.dis-4 {
  background: #21cd12 !important;
}

.dis-2 {
  background: #f59f39 !important;
}

.dis-3 {
  background: #fe3636 !important;
}

.dis-4 {
  background: #008000 !important;
}

.history-row .col-sm-1 {
  padding: 25px 15px;
  width: auto !important;
}

.history-row .col-sm-8 {
  width: 75% !important;
  padding-top: 5px;
}

.hishead-title {
  margin: 0px 0px 20px 0px;
}

body > section > div > div {
  margin: 50px 0 !important;
}

.history {
  padding: 5% 0 0 0;
}

.attnd-his {
  max-height: 290px;
}

.history-cont {
  overflow-y: scroll;
}

.hrms_content::-webkit-scrollbar-track,
.remark_data::-webkit-scrollbar-track,
.month_report_list::-webkit-scrollbar-track,
.history-cont::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.hrms_content::-webkit-scrollbar,
.remark_data::-webkit-scrollbar,
.month_report_list::-webkit-scrollbar,
.history-cont::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.hrms_content::-webkit-scrollbar-thumb,
.remark_data::-webkit-scrollbar-thumb,
.month_report_list::-webkit-scrollbar-thumb,
.history-cont::-webkit-scrollbar-thumb {
  background-color: #4934b6;
}

#emailHelpBlock {
  display: none;
}

@media (max-width: 787px) {
  .attendance-heading,
  .log-out {
    width: fit-content;
  }

  body > section > div > div {
    justify-content: space-between;
  }
}

.punchin_first {
  opacity: 0.4;
}

.d-cursor {
  cursor: not-allowed !important;
}

.toast:not(.show) {
  display: inline-flex;
  z-index: 9999;
}

/* =================================skeleton loading=================================== */
.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }

  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

.skeleton-mark-list {
  height: 89px;
}

.skeleton-text {
  width: 100%;
  height: 10.7rem;
  /* margin-bottom: 0.5rem;
    border-radius: 0.25rem; */
}

.skeleton-text__body {
  width: 75%;
}

.skeleton-footer {
  width: 30%;
}

/*======= attendance page =======*/

/* ===============login page======= */
.btn-outline-primary {
  margin: 0px 10px 0px 10px;
}

.change-email {
  display: none;
}

.digit-group input {
  width: 46px;
  height: 44px;
  background-color: white;
  border: none;
  line-height: 50px;
  text-align: center;
  font-size: 24px;
  font-family: 'Raleway';
  font-weight: 200;
  color: black;
  margin: 0 4px;
  border: 1px solid #0d6efd;
  border-radius: 0.375rem;
}

.digit-group .splitter {
  padding: 0 5px;
  color: black;
  font-size: 24px;
}

.prompt {
  margin-bottom: 20px;
  font-size: 20px;
  color: white;
}

.enterotpinput,
.verifyotpbtn {
  display: none;
  margin-left: 0px;
  width: 100%;
}

.auth-page {
  display: none;
}

#username_title {
  text-transform: lowercase;
}

#username_title:first-letter {
  text-transform: uppercase;
}

#digit-6 {
  margin-right: 0px;
  float: right;
}

#digit-1 {
  margin-left: 0px;
  float: left;
}

.form-control,
.form-select {
  /* border: 1px solid #0d6efd !important; */
}

/* =================== */

.sentotpbtn {
  width: 100% !important;
  margin: 0px !important;
}

.change-email {
  width: 100%;
  margin-right: 0px;
}

.extra-added-btn {
  display: flex;
}

/* ====== leaves page ====== */
.leave-his {
  height: 716px;
}

.leave-history-cont {
  overflow-y: scroll;
}

/* .leaves-page{
    position: relative;
    display: none;
} */
.history-heading {
  display: flex;
  justify-content: space-between;
  padding: 0 3%;
}

.leave-page-link {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #4934b6;
  line-height: 30px;
}

.leave-page-link:hover {
  color: #000000;
  cursor: pointer;
}

.leaves-heading {
  display: flex;
}

.leaves-heading a {
  padding: 1px 6px 6px 0px;
  margin-right: 15px;
}

.leaves-list .col-sm-12 div.days {
  width: fit-content;
}

.chart {
  text-align: center;
  font-family: Arial, sans-serif;
}

h1 {
  margin-bottom: 20px;
}

.accord {
  width: 100% !important;
}

.leaves-page .accordion-button {
  padding-right: 0px;
}

/* .leave-details {
  padding: 18px 0px 0px 14px;
  width: 80%!important;
} */

.leave-details p.approved span,
.approved {
  color: #21cd12;
}

.leave-details p.processing span,
.pending {
  color: #f59f39;
}

.leave-details p.rejected span,
.rejected {
  color: #fe3636;
}

.leave-icon {
  padding: 10px 15px;
  margin-left: -40px !important;
}

.position-relative {
  position: relative !important;
}

.days {
  position: absolute !important;
  top: 10px !important; /* Adjust as needed */
  right: 10px !important; /* Adjust as needed */
  color: #b3b0ed !important;
  z-index: 10 !important; /* Ensure it appears above other elements */
}

.accord-2 {
  width: 100%;
}

.leave-approved,
.performance-icon {
  padding: 10px;
  background: #4934b6;
  border-radius: 50%;
}

.leave-processing,
.listing-icon {
  padding: 10px;
  background: #f59f39;
  border-radius: 50%;
  width: 45px !important;
}

.leave-reject {
  padding: 10px;
  background: #fe3636;
  border-radius: 50%;
  width: 45px !important;
}

.duration {
  color: #b3b0ed;
  float: right;
  font-size: 1rem;
  line-height: 31px;
  position: absolute;
  right: 10px;
  top: 10px;
}

.date {
  margin: 0px;
}

/* ====== apply page ====== */
form > * {
  font-family: poppins;
}

.form-group {
  /* margin-bottom: 20px; */
  padding: 10px;
}

.apply-form-block label {
  font-size: 24px;
  margin-bottom: 5px;
}

body
  > section
  > section
  > div
  > form
  > div.form-group.col-lg-12.row
  > div:nth-child(1),
body
  > section
  > section
  > div
  > form
  > div:nth-child(4)
  > div
  > div:nth-child(1) {
  padding-left: 0px;
}

body
  > section
  > section
  > div
  > form
  > div.form-group.col-lg-12.row
  > div:nth-child(2),
body
  > section
  > section
  > div
  > form
  > div:nth-child(4)
  > div
  > div:nth-child(2) {
  padding-right: 0px;
}

.apply-form-block input,
.apply-form-block select,
.apply-form-block textarea {
  padding: 10px;
  background: #ffffff;
  border: 1.04854px solid #1976d2;
  border-radius: 5.24272px;
  width: 100%;
  color: #b3b3b3;
}

#half-day,
#full-day {
  width: fit-content;
  position: absolute;
  opacity: 0;
}

.half-day,
.full-day {
  width: 100%;
  text-align: center;
  background: #ffffff;
  border: 1.04854px solid #1976d2;
  border-radius: 5.24272px;
  color: #1976d2;
  padding: 5px;
}

.apply-page input[type='radio']:checked ~ label {
  background: #1976d2;
  color: white;
}

input[type='button'] {
  background: #21cd12;
  font-weight: 600;
  font-size: 24px;
  color: white;
  border: 1.04854px solid #21cd12;
}

.notification {
  color: #000;
  margin-right: 10px;
  display: flex;
  position: relative;
}

.notification:hover {
  color: #000;
}

.notification .count {
  content: '';
  width: 17px;
  height: 17px;
  border-radius: 6px;
  background-color: red;
  position: absolute;
  right: -5px;
  display: flex;
  font-size: 9px;
  top: -5px;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.col-5 {
  flex: 0 0 auto;
  width: 43.66666667%;
}

.collapse-data,
.collapse-data .card {
  border: none;
  border-radius: 10px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.collapse-head .history-row {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.rotate-svg {
  transform: rotate(180deg);
}

.admin-list a {
  color: #000;
}

.leave-item {
  margin-bottom: 10px;
}

#root {
  margin: 0px 10px;
}

.profile-left {
  display: flex;
  align-items: center;
}

.history-icon {
  background: #4934b6;
  font-size: 20px;
  padding: 5px;
  margin-right: 20px;
  border-radius: 50%;
  color: #fff;
  width: 40px;
  height: 40px;
  text-align: center;
}
.historySubTitle {
  font-size: 12px;
  font-weight: 600;
}

.right-bottom {
  margin-top: 15px;
  display: flex;
  height: 100%;
  align-items: flex-end;
}
.history-block {
  width: 100%;
  padding: 5px 15px;
  border: 1px solid #5b57e8;
  border-radius: 10px;
  background: #fff;
  margin-bottom: 20px;
}

.right-icon {
  background: #4934b6;
  padding: 10px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  margin-right: 4px;
}

.profile-icon img {
  margin-right: 10px;
  border-radius: 50%;
}

.profile-title {
  display: flex;
}

.profile-title::before {
  content: '';
  width: 5px;
  height: 18px;
  background: #5450d6;
  margin-right: 10px;
  border-radius: 50px;
}

.att-bottom {
  background: #fff;
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 10px;
  align-items: center;
}

.single-item,
.accordion-item {
  margin-bottom: 10px !important;
  border-radius: 10px !important;
}

.single-item2,
.accordion-item {
  margin-bottom: 10px !important;
  border-radius: 10px !important;
  padding: 10px 25px 10px 0px !important;
  /* width: 322px !important; */
  height: 78px !important;
}

.edit-button {
  border: none !important;
  background-color: white !important;
}

.accordion-item .accordion-button {
  border-radius: 10px !important;
  height: 80px !important;
}

.accordion-button:not(.collapsed),
.accordion-button:focus {
  color: #000;
  background-color: #fff;
  box-shadow: none;
}

.accordion-button::after {
  color: #b9b7ee;
}

.per-title,
.lis-title {
  color: #b3b3b3;
  font-size: 16px;
  font-weight: 400;
}

.per-content,
.lis-content {
  /* font-size: 15px; */
  font-weight: 400;
}

.accordion,
.accordion-item {
  border: none;
}

.per-submit,
.per-submit:hover,
.per-submit:active {
  background: #21cd12 !important;
  border: none;
  box-shadow: 0px 4px 4px 0px #00000040;
  width: 100%;
}

.listing-page.container .nav-tabs {
  background: #fff;
  border-radius: 5px;
}

.listing-page.container > ul > li.nav-item {
  width: 50%;
}

#controlled-tab-example-tab-History,
#controlled-tab-example-tab-Pending {
  width: 100%;
  color: #000;
  text-align: center;
  background: #fff;
}
#root > section > section > div > div > ul {
  --bs-nav-tabs-border-width: 0px;
}
#controlled-tab-example-tab-Pending {
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}
#controlled-tab-example-tab-History {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

#controlled-tab-example-tab-History.active,
#controlled-tab-example-tab-Pending.active {
  border: 1px solid #5450d6;
  border-radius: 5px;
  color: #5450d6;
}

.btn-approve {
  background: #21cd12 !important;
  box-shadow: 0px 4px 4px 0px #00000040;
  color: #fff !important;
  border-radius: 5px;
  width: 100% !important;
  margin-right: 20px !important;
}

.btn-reject {
  background: #fe3636 !important;
  box-shadow: 0px 4px 4px 0px #00000040;
  color: #fff !important;
  border-radius: 5px;
  width: 100% !important;
  margin-left: 20px !important;
  /* margin-left: 120px !important; */
}

.modal {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.modal-body {
  height: auto !important;
  background-color: #dbe9f7 !important;
  border-radius: 10px !important;
}

.modal-body2 {
  height: auto !important;
  background-color: #ffffff !important;
  border-radius: 10px !important;
}

/* .modal-dialog {
  width: 66% !important;
} */

textarea.form-control {
  height: 150px !important;
}

/* .modal-content {
  width: 100%;
  max-width: 800px;
} */

/* Optional: You can adjust the modal position if needed */
.modal-dialog {
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 83% !important;
}

.leave-container {
  position: relative;
  min-height: 100vh; /* Ensure the button stays at the bottom even if content is less */
}

.apply-leave-btn {
  position: fixed;
  bottom: 20px; /* Adjust this value as needed */
  right: 20px; /* Adjust this value as needed */
  z-index: 1000; /* Ensure it stays above other content */
}

.leave-application-btn {
  width: 60px;
  height: 60px;
  background: #4934b6;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2); /* Add shadow for depth */
}

.leave-application-btn svg {
  width: 30px;
  height: 30px;
  fill: white; /* SVG color */
}

.custom-margin {
  margin-left: 0px !important; /* Adjust the value as needed */
  margin-right: 0px !important; /* Adjust the value as needed */
}

.custom-accordion .card {
  border: none;
  border-radius: 15px; /* Adjust the border-radius as needed */
  overflow: hidden; /* Ensure children respect border-radius */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add box shadow */
  margin-bottom: 10px !important;
}

.custom-accordion .card-header {
  border-radius: 15px; /* Ensure header respects border-radius */
}

.custom-accordion .accordion-body {
  border-top: none; /* Remove the default border between header and body */
  border-radius: 15px; /* Ensure body respects border-radius */
}

.fixed-section {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff; /* Optionally, set a background color */
  z-index: 1000; /* Adjust the z-index as needed to ensure it's above other content */
}

.btn-close {
  height: 5px !important;
}

#root > div:nth-child(2) > div > div.card {
  border-radius: 10px;
  box-shadow: 0px 1.03px 1.03px 0px #00000040;
  overflow: hidden;
  margin-bottom: 6px;
}

.leaves-page .leaves-list .tab-content,
.leaves-page .leaves-list .nav-tabs {
  padding: 0px !important;
}

.hrms_footer {
  width: 100%;
}

.remark_data {
  max-height: 275px;
  overflow: auto;
}
