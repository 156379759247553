.loader_website {
  position: fixed;
  top: 0;
  left: 0px;
  z-index: 1100;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: block;

  -webkit-transition: ease-in-out 0.1s;
  -moz-transition: ease-in-out 0.1s;
  -o-transition: ease-in-out 0.1s;
  -ms-transition: ease-in-out 0.1s;
  transition: ease-in-out 0.1s;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}
.loader_website * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}
body.loader .loader_website span {
  top: 18%;
}
.loader_website > span {
  display: block;
  width: 48px;
  height: 48px;
  padding: 4px;
  background-color: #ffffff;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -o-border-radius: 100%;
  -ms-border-radius: 100%;
  border-radius: 100%;
  position: absolute;
  left: 50%;
  margin-left: -24px;
  top: -50px;

  -webkit-transition: ease-in-out 0.1s;
  -moz-transition: ease-in-out 0.1s;
  -o-transition: ease-in-out 0.1s;
  -ms-transition: ease-in-out 0.1s;
  transition: ease-in-out 0.1s;

  -webkit-box-shadow: #000 0px 5px 10px -5px;
  -moz-box-shadow: #000 0px 5px 10px -5px;
  -o-box-shadow: #000 0px 5px 10px -5px;
  -ms-box-shadow: #000 0px 5px 10px -5px;
  box-shadow: #000 0px 5px 10px -5px;
}
.loader_website > span > svg {
  fill: transparent;
  stroke: #1a73e8;
  stroke-width: 5;
  animation: loader_dash 2s ease infinite, loader_rotate 2s linear infinite;
}
@keyframes loader_dash {
  0% {
    stroke-dasharray: 1, 95;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 85, 95;
    stroke-dashoffset: -25;
  }
  100% {
    stroke-dasharray: 85, 95;
    stroke-dashoffset: -93;
  }
}
@keyframes loader_rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/*loader_website*/
