.justify-content-between.col-12.mt-3.mr-10.ml-10.extra-added-btn.d-flex {
    /* Adjust margin-right and margin-left of the container to create space */
    /* Adjust as needed */
  }
  
  .col-6.mr-3.text-center.extra-added-btn {
    /* Adjust margin-right of the button containers to create space between buttons */
    margin-right: 5px; /* Adjust as needed */
  }
  .sentotpbtn{
    width: 100% !important;
    margin: 0px !important;
}
.change-email {
    width: 100%;
    margin-right: 0px;
}