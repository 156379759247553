.design {
  display: flex;
  right: 20px;
  margin-left: 0;
  top: 110px;
  bottom: 2%;
}
.icon {
  display: flex;
  left: 33.33%;
  right: 7.83%;
  top: 7.83%;
  bottom: 33.33%;
  color: brown;
}
.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;

  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100%;
  height: 100%;
  background-color: var(--bs-backdrop-bg);
  font-family: poppins;
}
.btn btn-success {
  border: 1px solid rgb(33, 205, 18);
}

body {
  font-family: 'Poppins';
}
.birthday {
  width: 365px;
  height: 95px;
  position: relative;
  background-color: #ffc107;
  margin-left: 30px;
  margin-top: 20px;
  padding-top: 1px;
  border-radius: 8px;
  font-weight: '600';
  font-family: 'Poota One';
  text-align: center;
}
.images {
  position: absolute;
  width: 100%;
  height: 100%;
}
.birthdaywish {
  position: relative;
  text-align: center;
  background-color: #ffc107;
  margin: 13px;
  margin-top: 20px;
  padding: 20px 0px;
  border-radius: 8px;
  font-weight: '600';
  font-family: 'Poota One';
}

.imageswish {
  position: absolute;
}
.holiday {
  background: white;
  border-radius: 10px;
  position: relative;
  text-align: center;
  margin-top: 25px;
  border-radius: 8px;
  font-weight: '600';
  font-family: 'Poota One';
}
.holiday1 {
  height: 52px;
}
.back {
  position: absolute;
  width: 14px;
  height: 32px;
  background-color: #5450d6;
  margin-bottom: 40px;
  border-radius: 6px;
  margin-left: 170px;
}
.back1 {
  position: absolute;
  width: 14px;
  height: 32px;
  background-color: #5450d6;
  margin-bottom: 40px;
  border-radius: 6px;
  margin-right: 170px;
}
.slick-slide {
  padding: 0px 10px;
}
body > canvas {
  width: 100%;
  bottom: 10px;
}
